import React, { Component } from "react";
import Api from "../../Components/Api";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import DateTimePicker from "../../Components/DatePicker.js";
import moment from "moment";
import firebase from "firebase";
import VideocallUrl from "../../Components/VideocallUrl.js";
import $ from "jquery";
import OpenApiSuffix from "../../Components/Consult/SMCOpenApiSuffix.js";
UIkit.use(Icons);

export class ConsultAppointmentAddForm extends Component {
  consultId = 0;

  state = {
    campaign: [],
    consultInCampaign: [],
    campaignId: 0,
    memberInCampaign: [],
    memberFilterList: [],
    memberKeyword: "",
    memberSelect: {},
    chatMessageAppointmentBy: "",
    chatMessageAppointmentNote: "",
    chatMessageAppointmentType: "วีดีโอคอล",
    chatMessageAppointmentDate: moment().format("YYYY-MM-DD HH:mm"),
    chatMessageAppointmentName: "ติดตามอาการ",
  };

  memberInCampaignGet = async () => {
    var _r = await Api.consult("MemberInCampaign", {
      id: this.state.campaignId,
    });

    this.setState({
      memberInCampaign: _r.list || [],
    });
  };

  consultInCampaignGet = async () => {
    var _r = await Api.consult("ConsultInCampaign", {
      id: this.state.campaignId,
    });

    this.consultId = _r.consult?.id;

    this.setState({
      consultInCampaign: _r.list || [],
      chatMessageAppointmentBy: this.consultId,
    });
  };

  campaignGet = async () => {
    var _r = await Api.consult("CampaignList", {});

    if (!_r.list) _r.list = [];

    this.setState(
      {
        campaign: _r.list?.map(({ name, id }) => {
          return {
            label: name,
            value: id,
          };
        }),
        campaignId: _r.list[0]?.id,
      },
      () => {
        this.memberInCampaignGet();
        this.consultInCampaignGet();
      }
    );
  };

  firebaseChatlistUpdate = (consultid, memberid) => {
    firebase.database().ref(`chatlist_c/${consultid}`).set(+new Date());

    firebase.database().ref(`chatlist_m/${memberid}`).set(+new Date());
  };

  appointmentAdd = async () => {
    var _group_name = "";

    if (this.props.appointmentPopupType === "group") {
      _group_name = Math.random().toString(36).substring(7) + +new Date();
    }

    for (let memberid in this.state.memberSelect) {
      var _r = null;

      var _d = {
        consultId: this.state.chatMessageAppointmentBy,
        memberId: memberid,
        campaignId: this.state.campaignId,
        typeMessage: "appointment",
        memberRead: 0,
        consultRead: 1,
        chatby: "c",
        typeData: "appointment",
        appointmentType: this.state.chatMessageAppointmentType,
        appointmentDate: this.state.chatMessageAppointmentDate,
        appointmentName: this.state.chatMessageAppointmentName,
        appointmentNote: this.state.chatMessageAppointmentNote,
        appointmentBy: this.state.chatMessageAppointmentBy,
        appointmentGroupName: _group_name,
      };

      if (this.state.campaignId == 618) {
        //monkmeesuk

        this.setState({ loadingNotiApi: true });
        var _url = `https://server.dietz.asia/openApi/${
          OpenApiSuffix[this.state.campaignId].prefix
        }/${OpenApiSuffix[this.state.campaignId].version}/index.php`;

        _r = await $.ajax({
          method: "POST",
          url: _url,
          data: JSON.stringify({ apiPath: "AppointmentAdd", ..._d }),
        }).promise();

        UIkit.notification({
          message: _r.responseText,
        });
      } else {
        _r = await Api[this.apiType]("ChatMessageAdd", _d);

        UIkit.notification({
          message: _r.responseText,
        });
      }

      if (_r.result) {
        this.firebaseChatlistUpdate(this.consultId, memberid);

        this.props.callback(false);
      }
    }
  };

  componentDidMount() {
    this.campaignGet();
    this.datepickerGet();
  }

  datepickerGet = () => {
    var _this = this;

    $("#datetimepicker-appointment")
      .on("dp.change", function (e) {
        _this.setState({
          chatMessageAppointmentDate: moment(e.date).format("YYYY-MM-DD HH:mm"),
        });
      })
      .datetimepicker({
        format: "DD/MM/YYYY HH:mm",
        defaultDate:
          this.state.chatMessageAppointmentDate === "0000-00-00 00:00" ||
          !this.state.chatMessageAppointmentDate
            ? moment()
            : moment(
                this.state.chatMessageAppointmentDate,
                "YYYY-MM-DD HH:mm:ss"
              ),
      });
  };

  render() {
    return this.props.appointmentPopupType !== false ? (
      <div className="d-popup-background">
        <div className="d-popup-body">
          <div className="uk-text-right">
            <a uk-icon="close" onClick={() => this.props.callback(false)}></a>
          </div>
          <div>
            <h4>
              {this.props.appointmentPopupType === "single"
                ? "นัดหมายเดี่ยวรายบุคคล"
                : "นัดหมายกลุ่ม"}
            </h4>
            <div className="uk-position-relative">
              <div>ค้นหาคนไข้</div>
              <input
                className="uk-input"
                type="text"
                placeholder="ค้นหาคนไข้"
                value={this.state.memberKeyword}
                onChange={(e) => {
                  this.setState({
                    memberKeyword: e.target.value,
                    memberFilterList: this.state.memberInCampaign.filter(
                      (m) => {
                        return (
                          m.nameprefix.indexOf(e.target.value) > -1 ||
                          m.name.indexOf(e.target.value) > -1 ||
                          m.lastname.indexOf(e.target.value) > -1
                        );
                      }
                    ),
                  });
                }}
              />
              {this.state.memberKeyword && (
                <div id="member-search-container">
                  {this.state.memberFilterList.length ? (
                    this.state.memberFilterList.map((m) => {
                      return (
                        <div key={m.id}>
                          <span className="uk-text-truncate">
                            {m.nameprefix} {m.name} {m.lastname}
                          </span>{" "}
                          <a
                            className="uk-float-right"
                            uk-icon="plus"
                            onClick={() => {
                              var _memberSelect = this.state.memberSelect;

                              if (
                                this.props.appointmentPopupType === "single"
                              ) {
                                _memberSelect = {};
                              }

                              _memberSelect[
                                m.id
                              ] = `${m.nameprefix} ${m.name} ${m.lastname}`;
                              this.setState({
                                memberSelect: _memberSelect,
                                memberKeyword: "",
                              });
                            }}
                          ></a>
                        </div>
                      );
                    })
                  ) : (
                    <div>ไม่พบข้อมูล</div>
                  )}
                </div>
              )}
            </div>

            <div id="memberSelectContainer" className="uk-margin-top">
              {Object.keys(this.state.memberSelect).map((m) => {
                return (
                  <span className="uk-label uk-margin-small-right">
                    {this.state.memberSelect[m]}{" "}
                    <a
                      onClick={() => {
                        delete this.state.memberSelect[m];

                        this.setState({
                          memberSelect: this.state.memberSelect,
                        });
                      }}
                      uk-icon="close"
                    ></a>
                  </span>
                );
              })}
            </div>
            <div className="uk-grid uk-grid-small uk-child-width-1-1 uk-child-width-1-6@m">
              <div>
                <div>แคมเปญ</div>
                <select
                  className="uk-select"
                  value={this.state.campaignId}
                  onChange={(e) =>
                    this.setState({ campaignId: e.target.value }, () => {
                      this.memberInCampaignGet();
                      this.consultInCampaignGet();
                    })
                  }
                >
                  {this.state.campaign.map((option) => {
                    return (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <div>วันที่</div>
                <div
                  className="d-button-group input-group uk-button-group date"
                  id="datetimepicker-appointment"
                >
                  <input
                    type="text"
                    className="uk-input"
                    placeholder="วันที่/เวลา"
                  />
                  <span
                    className="uk-button input-group-addon"
                    style={{ width: 45, marginLeft: 0 }}
                  >
                    <span className="glyphicon glyphicon-calendar"></span>
                  </span>
                </div>
              </div>
              <div>
                <div>ช่องทาง</div>
                <select
                  value={this.state.chatMessageAppointmentType}
                  onChange={(e) =>
                    this.setState({
                      chatMessageAppointmentType: e.target.value,
                    })
                  }
                  className="uk-select uk-margin-small-bottom"
                >
                  {["วีดีโอคอล", "แชท", "โทรศัพท์", "ออฟไลน์"].map(
                    (option_value, option_index) => (
                      <option
                        value={option_value}
                        key={"appointment_" + option_index}
                      >
                        {option_value}
                      </option>
                    )
                  )}
                </select>
              </div>
              <div>
                <div>นัดหมายเพื่อ</div>
                <select
                  value={this.state.chatMessageAppointmentName}
                  onChange={(e) =>
                    this.setState({
                      chatMessageAppointmentName: e.target.value,
                    })
                  }
                  className="uk-select uk-margin-small-bottom"
                >
                  {["ติดตามอาการ", "ตรวจเพิ่มเติม", "อื่นๆ"].map(
                    (option_value, option_index) => (
                      <option value={option_value} key={"for_" + option_index}>
                        {option_value}
                      </option>
                    )
                  )}
                </select>
              </div>
              <div>
                <div>นัดหมายสำหรับ</div>
                <select
                  value={this.state.chatMessageAppointmentBy}
                  onChange={(e) =>
                    this.setState({ chatMessageAppointmentBy: e.target.value })
                  }
                  className="uk-select uk-margin-small-bottom"
                >
                  {this.state.consultInCampaign.map(
                    (option_value, option_index) => {
                      return (
                        <option
                          value={option_value.id}
                          key={"by_" + option_index}
                        >
                          {" "}
                          {this.consultId == option_value.id
                            ? "ตนเอง"
                            : `${option_value.nameprefix} ${option_value.name} ${option_value.lastname}`}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>
              <div>
                <div>หมายเหตุ</div>
                <textarea
                  className="uk-textarea"
                  placeholder="หมายเหตุ"
                  value={this.state.chatMessageAppointmentNote}
                  onChange={(e) =>
                    this.setState({
                      chatMessageAppointmentNote: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>

            <div className="uk-text-right">
              <br />
              <button
                className="uk-button uk-button-primary"
                onClick={this.appointmentAdd}
              >
                ส่งคำร้องการจอง
              </button>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <></>
    );
  }
}

export default class ConsultAppointmentList extends Component {
  state = {
    list: [],
    listAll: [],
    group: "self",
    dateStart: moment().startOf("day"),
    dateStop: moment().add(6, "day"),
    loadingApi: false,
    filterCampaign: "all",
    filterAppointmentType: "all",
    appointmentPopupType: false, //false, single, group
    hideFilterAll:
      this.props.consult.company?.companyid == 127 &&
      this.props.consult?.typeconsult === "แพทย์"
        ? true
        : false,
  };

  memberById = {};

  consultById = {};

  pharmacy = '';

  campaignOption = [{ label: "แคมเปญทั้งหมด", value: "all" }];

  appointmentType = [
    {
      label: "ออนไลน์ (วิดีโอคอล)",
      value: "วีดีโอคอล",
    },
    {
      label: "ออฟไลน์",
      value: "ออฟไลน์",
    },
    {
      label: "ทั้่งหมด",
      value: "all",
    },
  ];

  firebaseChatlistUpdate = (memberid, consultid) => {
    firebase.database().ref(`chatlist_m/${memberid}`).set(+new Date());
  };

  logVideoCallSend = async (consultId, memberId, campaignId, chatAppointmentId) => {
    var _r = await Api.member("LogVideoCallAdd", {
      consultId,
      memberId,
      campaignId,
      chatBy: "consult",
    });

    var result = await Api.consult("ChatMessageAdd", {
      consultId: consultId,
      memberId: memberId,
      campaignId: campaignId,
      typeMessage: "text",
      memberRead: 0,
      consultRead: 1,
      message:
        `มีการเรียกระบบวีดีโอคอล กดลิงค์ เพื่อเข้าสู่หน้าวีดีโอคอล ` +
        VideocallUrl(
          this.props.consult?.company?.companyid,
          memberId,
          consultId
        ),
      chatby: "c",
    });

    this.firebaseChatlistUpdate(memberId, consultId);
 
    console.log(this.props.consult?.company?.companyid,chatAppointmentId )

    if(this.props.consult?.company?.companyid == '127' && chatAppointmentId) {
      var _r = await Api.consult("M2VideoCallCalling", {
        chatAppointmentId
      }); 
    }

  };

  listGet = async () => {
    this.setState({ loadingApi: true });

    var { list, consult, member, pharmacy } = await Api.consult("AppointmentList2", {
      dateStart: moment(this.state.dateStart).format("YYYY-MM-DD 00:00:00"),
      dateStop: moment(this.state.dateStop).format("YYYY-MM-DD HH:mm:ss"),
      group: this.state.group,
    });

    if(pharmacy) {
      this.pharmacy = <div><b>เภสัชกร :</b>{pharmacy.map((p) => {
        return <div>{`${p.nameprefix} ${p.name} ${p.lastname}`} <b>โทร : {p.tel}</b></div>})}</div>
      
    }

    if (!list) list = [];
    if (!member) member = [];
    if (!consult) consult = [];

    member.forEach((data) => {
      this.memberById[data.id] = data;
    });

    consult.forEach((data) => {
      this.consultById[data.id] = data;
    });

    var _listByKey = {};

    list.forEach((list) => {
      if (_listByKey[list.campaignid] == null) {
        _listByKey[list.campaignid] = list.campaignname;
      }
    });

    this.campaignOption = [{ label: "แคมเปญทั้งหมด", value: "all" }];

    for (var k in _listByKey) {
      this.campaignOption.push({
        label: _listByKey[k],
        value: k,
      });
    }

    this.setState(
      {
        listAll: list,
        loadingApi: false,
      },
      () => {
        this.listFilterRender();
      }
    );
  };

  listFilterRender = () => {
    var _list = [];

    this.state.listAll.forEach((data) => {
      if (
        (this.state.filterCampaign === "all" ||
          this.state.filterCampaign == data.campaignid) &&
        (this.state.filterAppointmentType === "all" ||
          this.state.filterAppointmentType === data.type)
      ) {
        _list.push(data);
      }
    });

    this.setState({ list: _list });
  };

  vdocall_suggest_update = async (id, value) => {
    var _r = await Api.consult("AppointmentVdocallSuggestUpdate", {
      id,
      value,
    });
  };

  componentDidMount() {
    this.listGet();
  }

  render() {
    return (
      <div className="uk-padding">
        <div className="uk-card uk-card-default uk-padding-small">
          <div className="d-card-header uk-grid uk-grid-small">
            <div className="uk-width-expand">
              ตารางนัดแพทย์ และการให้คำแนะนำโดยพยาบาลหรือเภสัชกร
            </div>
            <div className="uk-width-1-3@m uk-width-1-1 uk-text-right">
              <button
                className="uk-button uk-button-small uk-button-primary uk-margin-small-bottom"
                onClick={() => {
                  this.setState({ appointmentPopupType: "single" });
                }}
              >
                <span uk-icon="plus"> </span>เพิ่มใบนัดเดี่ยว
              </button>
              <button
                className="uk-button uk-button-small uk-button-primary uk-margin-small-bottom uk-margin-left"
                onClick={() => {
                  this.setState({ appointmentPopupType: "group" });
                }}
              >
                <span uk-icon="plus"> </span>เพิ่มใบนัดแบบกลุ่ม
              </button>
            </div>
          </div>
          <div uk-grid="" className="uk-flex-middle">
            <div className="uk-width-1-6@m uk-width-1-1">
              <DateTimePicker
                id="dateStart"
                date={this.state.dateStart}
                callback={(value) => this.setState({ dateStart: value })}
              />
            </div>
            <div className="uk-width-1-6@m uk-width-1-1">
              <DateTimePicker
                id="dateStop"
                date={this.state.dateStop}
                callback={(value) => this.setState({ dateStop: value })}
              />
            </div>
            <div className="uk-width-1-6@m uk-width-1-1">
              <label className="uk-margin-small-right">
                <input
                  type="radio"
                  className="uk-radio"
                  name="group_select"
                  checked={this.state.group === "self"}
                  onChange={() => this.setState({ group: "self" })}
                />{" "}
                เฉพาะตนเอง
              </label>
              {this.state.hideFilterAll !== true && (
                <label>
                  <input
                    type="radio"
                    className="uk-radio"
                    name="group_select"
                    checked={this.state.group === "all"}
                    onChange={() => this.setState({ group: "all" })}
                  />{" "}
                  รวมทุกคน
                </label>
              )}
            </div>
            <div className="uk-width-1-6@m uk-width-1-1">
              <button
                className="uk-button uk-button-primary"
                onClick={this.listGet}
              >
                ค้นหา
              </button>
            </div>
            <div className="uk-width-auto@m uk-width-1-1">
              <div className="uk-flex uk-flex-middle">
                <span>ช่องทาง : </span>
                <select
                  className="uk-select uk-width-auto"
                  defaultValue={this.state.filterAppointmentType}
                  onChange={(e) =>
                    this.setState(
                      { filterAppointmentType: e.target.value },
                      () => {
                        this.listFilterRender();
                      }
                    )
                  }
                >
                  {this.appointmentType.map((option) => {
                    return (
                      <option
                        key={`appointemntType` + option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="uk-width-1-6@m uk-width-1-1">
              <select
                className="uk-select uk-width-expand"
                defaultValue={this.state.filterCampaign}
                onChange={(e) =>
                  this.setState({ filterCampaign: e.target.value }, () => {
                    this.listFilterRender();
                  })
                }
              >
                {this.campaignOption.map((option) => {
                  return (
                    <option
                      key={"campaignOption" + option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <hr />
          <div className="uk-overflow-auto">
            <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
              <thead>
                <tr>
                  <th>ที่</th>
                  <th>วันที่</th>
                  <th>เวลา</th>
                  <th>ชื่อคนไข้</th>
                  <th>นัดหมายเพื่อ</th>
                  <th>ผู้นัดหมาย</th>
                  <th>แคมเปญ</th>
                  <th>ช่องทาง</th>
                  <th>หมายเหตุ</th>
                  <th>สถานะจัดส่ง</th>
                  <th>การให้คำแนะนำ</th>
                </tr>
              </thead>
              <tbody>
                {this.state.loadingApi === true ? (
                  <tr>
                    <td colSpan="100%">
                      <div className="uk-padding-small uk-text-center">
                        <span uk-spinner=""></span>
                      </div>
                    </td>
                  </tr>
                ) : this.state.list.length === 0 ? (
                  <tr>
                    <td colSpan="100%">
                      <div className="uk-padding-small uk-text-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                ) : (
                  this.state.list.map((data, key) => {
                    var _member = this.memberById[data.memberid];

                    if (_member) {
                      _member = `${_member.nameprefix} ${_member.name} ${_member.lastname}`;
                    } else {
                      _member = "-";
                    }

                    var _consult = this.consultById[data.consultid];
                    if (_consult) {
                      _consult = `${_consult.nameprefix} ${_consult.name} ${
                        _consult.lastname
                      } (${_consult.username}) ${
                        _consult.typeconsult
                          ? `<ประเภท : ${_consult.typeconsult}>`
                          : ""
                      }`;
                    } else {
                      _consult = "-";
                    }

                    var _appointment_img = [];
                    if (data.image) {
                      try {
                        _appointment_img = JSON.parse(data.image);
                        _appointment_img = _appointment_img.map(() => {
                          return (
                            <span
                              uk-lightbox=""
                              className="uk-margin-small-right"
                            >
                              <a href={_appointment_img}>
                                <img
                                  src={_appointment_img}
                                  style={{ width: 50 }}
                                />
                              </a>
                            </span>
                          );
                        });
                      } catch (e) {}
                    } 
                    return (
                      <tr key={`row${data.id}`} data-id={data.id}>
                        <td>{key + 1}</td>
                        <td>{moment(data.dateadd).format("DD/MM/YYYY")}</td>
                        <td>{moment(data.dateadd).format("HH:mm น.")}</td>
                        <td>{_member}</td>
                        <td>
                          <a
                            href={VideocallUrl(
                              this.props.consult?.company?.companyid,
                              data.memberid,
                              data.consultid,
                              data.groupname
                            )}
                            className="uk-margin-right"
                            target="_blank"
                            onClick={() => {
                              this.logVideoCallSend(
                                data.consultid,
                                data.memberid,
                                data.campaignid,
                                data.chatappointmentid
                              );
                            }}
                          >
                            กดวีดีโอคอล
                          </a>
                          <a
                            href={`/consult/chat?messageSelectIndex=ca${data.campaignid}_co${data.consultid}_m${data.memberid}`}
                          >
                            {data.name}
                          </a>
                        </td>
                        <td>{_consult}</td>
                        <td>{data.campaignname}</td>
                        <td>
                          {data.type}
                          {data.groupname ? (
                            <div className="uk-text-meta d-text-green">
                              (นัดหมายกลุ่ม)
                            </div>
                          ) : (
                            <div className="uk-text-meta d-text-blue">
                              (นัดหมายเดี่ยว)
                            </div>
                          )}
                        </td>
                        <td>
                          <div className="uk-margin-small-bottom">
                            {data.note}{" "}
                          </div>
                          <div>{_appointment_img}</div>
                        </td>
                        <td>
                          {data.trackingcode ? (
                            <div>
                              <div className="uk-margin-small-top">
                                {" "}
                                <a
                                  rel="noopenner noreferer"
                                  target="_blank"
                                  href={
                                    "https://www.shippop.com/tracking?typeid=domestic&tracking_code=" +
                                    data.trackingcode
                                  }
                                >
                                  <span uk-icon="location"></span>{" "}
                                  ติดตามพัสดุเลขที่ {data.trackingcode}
                                </a>
                              </div>
                              <div className="uk-margin-small-top">
                                <a onClick={() => this.PrintShipping(data)}>
                                  <span uk-icon="print"></span> พิมพ์ใบปะหน้า
                                </a>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {data.shipping_status ? (
                            <div className="uk-margin-small-top">
                              <b>สถานะจัดส่ง :</b>{" "}
                              {data.shipping_status === "complete"
                                ? <><div>ส่งสำเร็จแล้ว</div>{this.pharmacy}</>
                                : data.shipping_status}
                            </div>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <label className="uk-text-normal">
                            <input
                              type="checkbox"
                              className="uk-checkbox"
                              checked={data.vdocall_suggest == "1"}
                              onChange={(e) => {
                                data.vdocall_suggest =
                                  e.target.checked === true ? "1" : "0";
                                this.vdocall_suggest_update(
                                  data.chatappointmentid,
                                  data.vdocall_suggest
                                );
                                this.forceUpdate();
                              }}
                            />{" "}
                            ให้คำแนะนำผู้ป่วยทางวีดีโอคอลแล้ว
                          </label>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
        {this.state.appointmentPopupType !== false && (
          <ConsultAppointmentAddForm
            appointmentPopupType={this.state.appointmentPopupType}
            campaign={this.campaignOption}
            callback={(value) => this.setState({ appointmentPopupType: value })}
          />
        )}
      </div>
    );
  }
}
