import React, { Component } from 'react';
import Api from '../../Components/Api';
import LoginForm from '../../Components/LoginForm';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import LanguageLoginSelect from '../../Components/LanguageLoginSelect';
import __ from '../../Components/Language';

UIkit.use(Icons);

export default class MemberLogin extends Component {
  login = async (data) => {
    var { result, responseText, session } = await Api.member('MemberLoginByForm', data);

    UIkit.notification(`<span uk-icon='icon: ${result ? 'check' : 'close'}'></span> ${__(responseText)}`);

    if (result && session) {
      localStorage.setItem('memberLogin', session);
 

      if(this.props.qs.redirect) {
        window.location.href = `${decodeURIComponent(this.props.qs.redirect)}?route=${decodeURIComponent(this.props.qs.redirect)}&token_member=${encodeURIComponent(session)}`;
      }
      else if(this.props.qs.menu === 'record') {
          window.location.href =  `https://smarthealth-line.dietz.asia/data?route=${encodeURIComponent(`/data?redirect=${encodeURIComponent('https://smarthealth.dietz.asia/user-smart-health')}`)}&token_member=${encodeURIComponent(session)}`;
      }
      else if(this.props.qs.menu === 'risk') {
        window.location.href = "/user-smart-health#d-smarth-health-trending-graph";
      }
      else if(this.props.qs.menu === 'display') {
        window.location.href =  `https://liff.line.me/2006538121-y9BkoYb3?token_member=${encodeURIComponent(session)}`;
      }
      else {
        window.location.reload();
      }
      
    }
  }

  render() {
    return <div>
      <div className="d-form-login-body uk-padding-small">
        <div className="uk-card uk-card-default uk-padding uk-margin-top uk-padding-remove-top d-form-login-container uk-width-1-1 uk-width-1-2@s uk-width-1-4@m">
          <LanguageLoginSelect />
          <div className="uk-text-center uk-padding-small">
            <img src="/logo_dietz.png" />
          </div>
          <h1 className="d-form-login-title">{__('เข้าสู่ระบบ')}</h1>
          <LoginForm {...this.props} onSubmit={this.login} />
        </div>
      </div>
    </div>
  }
};
