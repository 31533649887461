import React, {Component} from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import moment from 'moment';
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js";
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css";
import $ from 'jquery';

UIkit.use(Icons);

export default class DatePicker2 extends Component {
  state = {
    year: '2025',
    month: '01',
    day: '01'
  }

  componentDidMount () { 
    
  }

  callback = () => { 
    if(typeof this.props.callback === 'function')
    this.props.callback(`${this.state.year}-${this.state.month}-${this.state.day}`)
  }

  yearSelectRender = () => {
    var _year = [];
    var _this_year = parseInt(moment().format('YYYY')) + 543;

    for(var i=_this_year; i>2400; i--) {
      _year.push(<option key={`date-picker2-year-${i}`} value={i-543}>{i}</option>)
        }

    return <select className='uk-select' defaultValue={this.state.year} onChange={e=>this.setState({year: e.target.value}, this.callback)}>{_year}</select>
  }

  render() {
    return  <div className='uk-grid uk-grid-small uk-child-width-1-3'>
      <div>
        <select className='uk-select' defaultValue={this.state.day} onChange={e=>this.setState({day: e.target.value}, this.callback)}>{
          Array.from({ length: 31 }, (_, i) => (<option key={`date-picker2-day-${i}`} value={i+1}>{i < 9 ? '0' + (i + 1) : i + 1}</option>))  }
          </select>
      </div>
      <div>
        <select className='uk-input' defaultValue={this.state.month} onChange={e=>this.setState({month: e.target.value}, this.callback)}>
                <option value="01">มกราคม</option>
                <option value="02">กุมภาพันธ์</option>
                <option value="03">มีนาคม</option>
                <option value="04">เมษายน</option>
                <option value="05">พฤษภาคม</option>
                <option value="06">มิถุนายน</option>
                <option value="07">กรกฎาคม</option>
                <option value="08">สิงหาคม</option>
                <option value="09">กันยายน</option>
                <option value="10">ตุลาคม</option>
                <option value="11">พฤศจิกายน</option>
                <option value="12">ธันวาคม</option>
              </select>
      </div>
      <div>
        {this.yearSelectRender()}
      </div>
    </div>
  }
};
