import React, { Component } from "react";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { Link } from "react-router-dom";
import UploadFirebase from "../../Components/UploadFirebase";
import Api from "../../Components/Api";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js";
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css";
import moment from "moment";
import LanguageLoginSelect from "../../Components/LanguageLoginSelect";
import __ from "../../Components/Language";
import numeral from "numeral";
import ImageUri from "../../Components/ImageUri";
import MemberRegisterLocation from "../../Components/Member/MemberRegisterLocation";
import DatePicker2 from "../../Components/DatePicker2";

UIkit.use(Icons);

export default class MemberRegister extends Component {
  state = {
    username: "",
    password: "",
    nameprefix: "",
    name: "",
    lastname: "",
    email: "",
    gender: "1",
    campaignid: this.props.qs.caid || "",
    campaignname: "",
    campaigncaption: "",
    campaign: null,
    companyid: this.props.qs.coid || "",
    companyname: "",
    companyimage: "",
    nid: "",
    birthday: "2025-01-01",
    image: "",
    lineid: "",
    step: 1,
    otp: "",
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    tel: "",
    accept: false,
    errorTextStep1: "",
    errorTextStep2: "",
    errorTextStep3: "",
    loadingStep1: false,
    loadingStep2: false,
    loadingStep3: false,
    acceptDelicate: false,
    paymentType: "1",
    promocode: "",
    memberpay: {},
    payForm: false,
    payComplete: false,
    campaignList: [],
    lat: "",
    lng: "",
    address_name: "",
    address: "",
    address_note: "",
    postcode: "",
    display: this.props.qs.display || "",
    consent_tele_agree: false,
    consent_tele_show: false,
    can_register: false,
    method: this.props.qs.method || "",
  };

  async componentDidMount() {
    if (this.props.qs.coid) {
      this.setState({ can_register: true });
    }

    if (this.props.qs.display === "list" && this.props.qs.coid) {
      var _response = await Api.member("CampaignByCompanyId", {
        coid: this.props.qs.coid,
      });

      this.setState({
        campaignList: _response.data || [],
        companyname: _response.company ? _response.company.name : "",
        companyimage: _response.company ? _response.company.image : "",
      });
    }

    if (this.props.qs.caid && this.props.qs.coid) {
      var _response = await Api.member("CompanyCampaignById", {
        caid: this.props.qs.caid,
        coid: this.props.qs.coid,
        mpid: this.props.qs.mpid || null,
      });

      if (_response.result === true) {
        var _data = {
          campaignname: _response.ca_name,
          campaigncaption: _response.ca_caption,
          companyname: _response.co_name,
          campaign: _response.campaign || null,
          companyimage: _response.co_image,
        };

        if (_response.memberpay != null) {
          try {
            _data.memberpay = { id: _response.memberpay.id };

            var _detail = JSON.parse(_response.memberpay.detail);
            _data.username = _detail.username;
            _data.tel = _detail.tel;
            _data.password = _detail.password;

            if (_response.memberpay.paystatus === "COMPLETED") {
              _data.payComplete = true;
            } else {
              _data.payComplete = false;
              _data.errorTextStep1 =
                _response.memberpay.paystatus ||
                __("โปรดชำระเงินเพื่อดำเนินการต่อ");
            }
          } catch (e) {}
        }

        this.setState(_data);
      }
    }
  }

  registerStart = async (e) => {
    e.preventDefault();

    var _valid_input = ["username", "password", "tel"];

    if(this.props.qs.method === 'lite') {
      _valid_input = ['name', 'lastname', 'tel', 'nid'];
    }

    var _valid_input_location = [
      "address_name",
      "address",
      "postcode",
      "lat",
      "lng",
    ];

    try {
      _valid_input.forEach((value) => {
        if (!this.state[value]) throw new Error("โปรดใส่ข้อมูลให้ครบ");
      });

      if (this.props.qs.display === "location") {
        _valid_input_location.forEach((value) => {
          if (!this.state[value]) throw new Error("โปรดใส่ข้อมูลให้ครบ");
        });
      }

      if (this.state.accept !== true) {
        throw new Error("โปรดยอมรับเงื่อนไขและนโยบาย");
      } else if (
        this.state.consent_tele_agree !== true &&
        this.state.companyname
      ) {
        throw new Error(
          "คุณต้องยอมรับเงื่อนไขการรับบริการการแพทย์ทางไกลของสถานพยาบาล"
        );
      } else if (this.props.qs.method !== 'lite' && this.state.password.length < 8) {
        throw new Error(
          "รหัสผ่านควรตั้งให้มี 8 ตัวขึ้นไป ประกอบด้วยตัวเลขและตัวอักษรเพื่อความปลอดภัย"
        );
      } else {
        if (this.state.loadingStep1 === true) return false;

        this.setState({ loadingStep1: true });

        var _response = await Api.member("MemberRegisterByForm", this.state);

        if (_response.result === true) {
          if (_response.memberpay == null) {
            await this.otpSend();

            this.setState({
              step: 2,
              loadingStep1: false,
              loadingStep2: false,
            });
          } else {
            this.setState(
              { memberpay: _response.memberpay, payForm: true },
              () => {
                $("#d-register-pay-form").submit();
              }
            );
          }
        } else {
          throw new Error(_response.responseText);
        }
      }
    } catch (e) {
      this.setState({ errorTextStep1: __(e.message), loadingStep1: false });
    }
  };

  otpSend = async () => {
    var _response = await Api.member("MemberRegisterOTP", this.state);

    UIkit.notification({ message: __("ส่งรหัส OTP ไปยัง ") + this.state.tel });

    return true;
  };

  otpConfirm = (e) => {
    e.preventDefault();

    this.setState(
      {
        otp:
          this.state.otp1.toString() +
          this.state.otp2.toString() +
          this.state.otp3.toString() +
          this.state.otp4.toString(),
      },
      async () => {
        var _response = await Api.member("MemberRegisterByForm", this.state);

        if (_response.result === true) {

          if(this.props.qs.method === 'lite') {
            localStorage.setItem("memberLogin", _response.session);

            if(this.props.qs.menu === 'record') {
              

              window.location.href =  `https://smarthealth-line.dietz.asia/data?route=${encodeURIComponent(`/data?redirect=${encodeURIComponent('https://smarthealth.dietz.asia/user-smart-health')}`)}&token_member=${encodeURIComponent(_response.session)}`
            }
            else if(this.props.qs.menu === 'risk') {
              window.location.href = "/user-smart-health#d-smarth-health-trending-graph";
            }
 
            else if(this.props.qs.menu === 'display') {
              window.location.href =  `https://liff.line.me/2006538121-y9BkoYb3?token_member=${encodeURIComponent(_response.session)}`;
            }
          } else {

            this.setState({ step: 3, loadingStep2: false });
          }


        } else {
          this.setState({
            errorTextStep2: _response.responseText,
            loadingStep2: false,
          });
        }
      }
    );
  };

  registerConfirm = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      var _valid_input = [
        "nameprefix",
        "name",
        "lastname",
        "nid",
        "campaignid",
        "companyid",
      ];

      _valid_input.forEach((value) => {
        if (!this.state[value]) {
          if (["campaignid", "companyid"].includes(value) === true) {
            if (
              !(value === "campaignid" && this.props.qs.display === "location")
            )
              throw new Error("โปรดติดต่อรพ.ของท่านเพื่อสอบถามรหัส");
          } else {
            throw new Error("โปรดใส่ข้อมูลให้ครบ");
          }
        }
      });

      if (this.state.acceptDelicate !== true) {
        throw new Error(
          "คุณต้องยอมรับการใช้ข้อมูลส่วนบุคคลบุคคลที่มีความอ่อนไหวเป็นพิเศษ"
        );
      } else if (
        this.state.email !== "" &&
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.state.email
        ) !== true
      ) {
        throw new Error("รูปแบบของอีเมลไม่ถูกต้อง");
      } else {
        var _response = await Api.member("MemberRegisterByForm", this.state);

        if (_response.result === true) {
          localStorage.setItem("memberLogin", _response.session);
          /*
          window.location.href = `https://smarthealth-line.dietz.asia/data?route=${encodeURIComponent(
            `/data?redirect=${encodeURIComponent(
              "https://smarthealth.dietz.asia"
            )}`
          )}&token_member=${encodeURIComponent(
            localStorage.getItem("memberLogin")
          )}`;
          */

          window.location.href = "https://smarthealth.dietz.asia/chat";

          this.setState({ errorTextStep3: "" });
        } else {
          throw new Error(_response.responseText);
        }
      }
    } catch (e) {
      this.setState({ errorTextStep3: __(e.message) });
    }
  };

  updateValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  loadingRender = () => {
    return (
      <div className="uk-text-center uk-padding-small">
        <span uk-spinner=""></span>
      </div>
    );
  };

  pay = () => {
    return (
      this.state.payForm === true && (
        <form
          method="post"
          action="https://www.thaiepay.com/epaylink/payment.aspx"
          target="_self"
          id="d-register-pay-form"
        >
          <input type="hidden" name="refno" value={this.state.memberpay.id} />
          <input type="hidden" name="merchantid" value="17332033" />
          <input
            type="hidden"
            name="customeremail"
            value="register@dietz.asia"
          />
          <input type="hidden" name="cc" value="00" />
          <input
            type="hidden"
            name="productdetail"
            value={`ชำระเงินค่าสมัครสมาชิก ca=${this.props.qs.caid}, co=${this.props.qs.coid}`}
          />
          <input
            type="hidden"
            name="total"
            value={numeral(this.state.memberpay.price).format("0.00")}
          />
          <input
            type="hidden"
            name="returnurl"
            value={`https://smarthealth.dietz.asia/register?coid=${this.props.qs.coid}&caid=${this.props.qs.caid}&mpid=${this.state.memberpay.id}`}
          ></input>
        </form>
      )
    );
  };

  step1 = () => {
    return (
      <div>
        {this.pay()}
        <form
          onSubmit={this.registerStart}
          className="uk-text-left uk-margin-small-top"
        >
          {this.props.qs.method !== "lite" ? (
            <>
              <div>
                <div>
                  {__(
                    "ชื่อผู้ใช้ (ภาษาอังกฤษหรือตัวเลข สามารถใช้เลขบัตรประชาชนได้)"
                  )}
                  <span className="uk-text-danger">*</span>
                </div>
                <input
                  value={this.state.username}
                  className="uk-input"
                  type="text"
                  name="username"
                  onChange={(e) => {
                    this.setState({
                      username: e.target.value.replace(/[^a-zA-Z0-9]/gi, ""),
                    });
                  }}
                />
              </div>
              <div>
                <div>
                  {__(
                    "รหัสผ่าน (สามารถใช้วันเดือนปีเกิดเป็นรหัสผ่านได้ เช่น 10082524)"
                  )}
                  <span className="uk-text-danger">*</span>
                </div>
                <input
                  value={this.state.password}
                  className="uk-input"
                  type="password"
                  name="password"
                  onChange={(e) => {
                    this.setState({
                      password: e.target.value.replace(/[^a-zA-Z0-9]/gi, ""),
                    });
                  }}
                />
              </div>
              <div>
                <ul>
                  <li>{__("มีจำนวน 8 ตัว")}</li>
                  <li>{__("เป็นตัวเลขหรือตัวอักษร")}</li>
                </ul>
              </div>
              <div className="uk-margin-small-top">
                <div>
                  {__("เบอร์โทรศัพท์มือถือ")}
                  <span className="uk-text-danger">*</span>
                </div>
                <input
                  type="number"
                  pattern="[0-9]*"
                  className="uk-input"
                  value={this.state.tel}
                  onChange={(e) =>
                    this.setState({ tel: e.target.value.replace(/\D/, "") })
                  }
                />
              </div>
            </>
          ) : (
            <>
              <div>
                <div>
                  {__("ชื่อ")}
                  <span className="uk-text-danger">*</span>
                </div>
                <input
                  type="text"
                  className="uk-input"
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
              <div>
                <div>
                  {__("นามสกุล")}
                  <span className="uk-text-danger">*</span>
                </div>
                <input
                  type="text"
                  className="uk-input"
                  onChange={(e) => this.setState({ lastname: e.target.value })}
                />
              </div>
              <div>
                <div>
                  {__("วันเดือนปีเกิด")} ({__("พ.ศ.")})
                  <span className="uk-text-danger">*</span>
                </div>
                <DatePicker2
                  callback={(d) => {
                    this.setState({"birthday": d});
                  }}
                />
              </div>

              <div className="uk-margin-small-top">
                <div>
                  {__("เบอร์โทรศัพท์มือถือ")}
                  <span className="uk-text-danger">*</span>
                </div>
                <input
                  type="number"
                  pattern="[0-9]*"
                  className="uk-input"
                  value={this.state.tel}
                  onChange={(e) =>
                    this.setState({ tel: e.target.value.replace(/\D/, "") })
                  }
                />
              </div>
              <div>
                <div>
                  {__("เลขบัตรประชาชน")}
                  <span className="uk-text-danger">*</span>
                </div>
                <input
                  type="number"
                  pattern="[0-9]*"
                  className="uk-input"
                  value={this.state.nid}
                  onChange={(e) =>
                    this.setState({ nid: e.target.value.replace(/\D/, "") })
                  }
                />
              </div>
            </>
          )}
          {this.state.campaignname && (
            <div>
              <div>{__("สถานพยาบาล")}</div>
              <input
                value={this.state.companyname}
                disabled={true}
                className="uk-input"
                type="text"
              />
            </div>
          )}
          {this.state.companyname && (
            <div>
              <div>{__("แคมเปญ")}</div>
              <input
                value={this.state.campaignname}
                disabled={true}
                className="uk-input"
                type="text"
              />
              {this.state.campaigncaption && (
                <div
                  className="uk-margin-small-top uk-margin-small-bottom uk-text-meta"
                  dangerouslySetInnerHTML={{
                    __html: this.state.campaigncaption.replaceAll(
                      "\n",
                      "<br />"
                    ),
                  }}
                ></div>
              )}
            </div>
          )}
          {this.props.qs.display !== "location" &&
            !(this.state.campaignname && this.state.companyname) && (
              <div>
                {__("สถานพยาบาล")} : {__("ทั่วไป")}
              </div>
            )}
          {this.state.campaign && +this.state.campaign.day > 0 && (
            <div>
              <div>
                <div>{__("ระยะเวลาแคมเปญ กรณีคนไข้รายบุคคล")}</div>
                <input
                  type="text"
                  className="uk-input"
                  disabled="disabled"
                  value={this.state.campaign.day + __(" วัน")}
                />
              </div>
              <div>
                <div>{__("วันที่สมัคร")}</div>
                <input
                  type="text"
                  className="uk-input"
                  disabled="disabled"
                  value={moment().add(543, "year").format("DD/MM/YYYY")}
                />
              </div>
              <div>
                <div>{__("วันที่หมดอายุ")}</div>
                <input
                  type="text"
                  className="uk-input"
                  disabled="disabled"
                  value={moment()
                    .add(+this.state.campaign.day + 1, "d")
                    .add(543, "year")
                    .format("DD/MM/YYYY")}
                />
              </div>
              {this.state.campaign.pay === "1" && (
                <div>
                  <div>
                    <div>{__("ค่าใช้จ่าย")}</div>
                    <input
                      type="text"
                      className="uk-input"
                      disabled="disabled"
                      value={numeral(this.state.campaign.price).format(
                        "0,0.00"
                      )}
                    />
                  </div>
                  {this.state.payComplete !== true ? (
                    <div>
                      <div className="uk-margin-small-top">
                        <div>
                          {__(
                            "กรุณาชำระค่าบริการก่อนกดสมัครสมาชิก วิธีใดวิธีหนึ่ง"
                          )}
                        </div>
                        <div className=" uk-margin-small-top">
                          <label className="uk-width-1-1">
                            <input
                              type="radio"
                              name="payment-type"
                              checked={
                                this.state.paymentType === "0" ? true : false
                              }
                              onChange={() =>
                                this.setState({ paymentType: "0" })
                              }
                            />{" "}
                            {__("กรอกรหัสโปรโมชัน")}{" "}
                            <input
                              type="text"
                              className="uk-input uk-text-center uk-margin-small-top"
                              value={this.state.promocode}
                              onChange={(e) =>
                                this.setState({ promocode: e.target.value })
                              }
                            />
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="radio"
                              name="payment-type"
                              checked={
                                this.state.paymentType === "1" ? true : false
                              }
                              onChange={() =>
                                this.setState({ paymentType: "1" })
                              }
                            />{" "}
                            {__("ชำระเงิน")}
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="uk-padding-small uk-text-center uk-background-muted uk-text-success uk-margin-top">
                      {__("ชำระเงินแล้ว")}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {this.props.qs.display === "location" && (
            <MemberRegisterLocation
              {...this.props}
              callback={(data) => {
                this.setState({
                  address_name: data.address_name,
                  address: data.address,
                  address_note: data.address_note,
                  postcode: data.postcode,
                  lat: data.lat,
                  lng: data.lng,
                });
              }}
            />
          )}

          <div className="uk-margin-top">
            <label uk-grid="" className="uk-grid-small">
              <div className="uk-width-auto">
                <input
                  type="checkbox"
                  className="uk-checkbox"
                  id="d-accept-policy"
                  checked={this.state.accept === true ? "checked" : ""}
                  onChange={() => {
                    this.setState({
                      accept: $("#d-accept-policy").prop("checked"),
                    });
                  }}
                />
              </div>
              <div className="uk-width-expand">
                {__("คุณได้อ่านและยอมรับ")}{" "}
                <a
                  href="https://dietz.asia/terms.html"
                  target="_blank"
                  className="uk-link-reset"
                  rel="noopener"
                >
                  <u>{__("ประกาศนโยบายความเป็นส่วนตัว (Privacy Notice)")}</u>
                </a>
              </div>
            </label>
          </div>
          {this.state.companyname && (
            <div className="uk-margin-top">
              <label uk-grid="" className="uk-grid-small">
                <div className="uk-width-auto">
                  <input
                    type="checkbox"
                    className="uk-checkbox"
                    checked={
                      this.state.consent_tele_agree === true ? "checked" : ""
                    }
                    onChange={() => {
                      this.setState({
                        consent_tele_agree: !this.state.consent_tele_agree,
                      });
                    }}
                  />
                </div>
                <div className="uk-width-expand">
                  <a
                    className="uk-link-reset"
                    onClick={() => this.setState({ consent_tele_show: true })}
                  >
                    <u>
                      {__(
                        "คุณได้อ่านและยินยอมเงื่อนไขการรับบริการการแพทย์ทางไกล (Inform Consent) ของสถานพยาบาล"
                      )}
                    </u>
                  </a>
                </div>
              </label>
              {this.state.consent_tele_show === true && (
                <div className="d-popup-background">
                  <div className="d-popup-body">
                    <div className="uk-text-right">
                      <button
                        uk-close=""
                        onClick={() => {
                          this.setState({ consent_tele_show: false });
                        }}
                      ></button>
                    </div>
                    <h4 className="uk-text-center d-font-prompt">
                      สถานพยาบาล {this.state.companyname}
                    </h4>
                    <p>
                      ข้าพเจ้า เป็นผู้ป่วย หรือ ผู้แทนผู้ป่วย
                      ข้าพเจ้าให้ความยินยอมรับบริการทางไกลจากแพทย์ หรือ
                      ผู้ที่ได้รับการแต่งตั้งจากแพทย์
                      โดยได้รับการชี้แจงเกี่ยวกับความเสี่ยง
                      และข้อมูลสุขภาพส่วนบุคคล
                      ที่อาจใช้ในบริการทางการแพทย์ทางไกล
                    </p>
                    <p>
                      เมื่อให้การยินยอมโดยการเลือกยินยอมในแบบฟอร์อิเล็กทรอนิกส์นี้
                      จะถือว่า ข้าพเจ้าได้รับทราบข้อมูลและยินยอม เรียบร้อยแล้ว
                    </p>
                    <div className="uk-text-right">
                      <button
                        className="uk-button uk-button-primary"
                        onClick={() => {
                          this.setState({ consent_tele_show: false });
                        }}
                      >
                        ปิด
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {this.state.errorTextStep1 && (
            <div className="uk-margin-small-top uk-text-center uk-text-danger uk-padding-small uk-background-muted">
              {this.state.errorTextStep1}
            </div>
          )}

          <div>
            {this.state.loadingStep1 === true ? (
              this.loadingRender()
            ) : (
              <button
                className="uk-button uk-button-primary uk-width-1-1 uk-margin-top"
                type="submit"
              >
                {__(
                  this.state.paymentType === "1" &&
                    this.state.campaign &&
                    this.state.campaign.pay === "1" &&
                    this.state.payComplete !== true
                    ? "ชำระเงิน"
                    : "สมัครสมาชิก"
                )}
              </button>
            )}
          </div>
          <div className="uk-margin-top uk-text-center">
            {__("หากคุณมีบัญชีกับเราแล้ว")}{" "}
            <Link to={"/" + (this.props.qs.menu ? `?menu=${this.props.qs.menu}` : '')} className="uk-link-reset">
              <u>{__("ล็อคอินเข้าสู่ระบบ")}</u>
            </Link>
          </div>
        </form>
      </div>
    );
  };

  otpTextInputRender = (e, number) => {
    e.target.value = e.target.value.replace(/\D/, "");

    this.setState({ [`otp${number}`]: e.target.value });

    if (e.target.value && number !== "4")
      $("#otp-input-" + (+number + 1)).focus();

    if (number === "4") $("#otp-input-4").blur();
  };

  step2 = () => {
    return (
      <form
        className="uk-text-left uk-margin-small-top uk-text-center"
        onSubmit={this.otpConfirm}
      >
        <div className="uk-margin-small-bottom">
          <div className="d-text-title">{__("กรุณากรอกรหัส 4 หลัก")}</div>
          <div className="d-text-title">{__("ที่ท่านได้รับจาก SMS")}</div>
        </div>
        <div className="uk-margin-top">
          <input
            type="tel"
            id="otp-input-1"
            maxLength="1"
            pattern="[0-9]*"
            className="uk-input d-input-otp"
            value={this.state.otp1}
            onChange={(e) => {
              this.otpTextInputRender(e, "1");
            }}
          />
          <input
            type="tel"
            id="otp-input-2"
            maxLength="1"
            pattern="[0-9]*"
            className="uk-input d-input-otp"
            value={this.state.otp2}
            onChange={(e) => {
              this.otpTextInputRender(e, "2");
            }}
          />
          <input
            type="tel"
            id="otp-input-3"
            maxLength="1"
            pattern="[0-9]*"
            className="uk-input d-input-otp"
            value={this.state.otp3}
            onChange={(e) => {
              this.otpTextInputRender(e, "3");
            }}
          />
          <input
            type="tel"
            id="otp-input-4"
            maxLength="1"
            pattern="[0-9]*"
            className="uk-input d-input-otp"
            value={this.state.otp4}
            onChange={(e) => {
              this.otpTextInputRender(e, "4");
            }}
          />
        </div>

        {this.state.errorTextStep2 && (
          <div className="uk-margin-small-top uk-text-center uk-text-danger uk-padding-small uk-background-muted">
            {this.state.errorTextStep2}
          </div>
        )}

        <div className="uk-margin-top">
          <button
            className="uk-button uk-button-primary uk-width-1-1 uk-margin-top"
            type="submit"
          >
            {__("ยืนยันสมัครสมาชิก")}
          </button>
        </div>

        <div className="uk-text-center uk-margin-top">
          {__("ยังไม่ได้รับ SMS")}{" "}
          <a className="uk-link-reset" onClick={this.otpSend}>
            <u>{__("กดส่งใหม่")}</u>
          </a>
        </div>
      </form>
    );
  };

  step3 = () => {
    return (
      <div className="uk-text-left">
        <div>
          <div>
            {__("คำนำหน้าชื่อ")}
            <span className="uk-text-danger">*</span>
          </div>
          <input
            type="text"
            className="uk-input"
            onChange={(e) => this.setState({ nameprefix: e.target.value })}
          />
        </div>
        <div>
          <div>
            {__("ชื่อ")}
            <span className="uk-text-danger">*</span>
          </div>
          <input
            type="text"
            className="uk-input"
            onChange={(e) => this.setState({ name: e.target.value })}
          />
        </div>
        <div>
          <div>
            {__("นามสกุล")}
            <span className="uk-text-danger">*</span>
          </div>
          <input
            type="text"
            className="uk-input"
            onChange={(e) => this.setState({ lastname: e.target.value })}
          />
        </div>
        <div>
          <div>{__("อีเมล")}</div>
          <input
            type="email"
            className="uk-input"
            onChange={(e) => this.setState({ email: e.target.value })}
          />
        </div>
        <div>
          <div>
            {__("เพศ")}
            <span className="uk-text-danger">*</span>
          </div>
          <select
            className="uk-select"
            onChange={(e) => this.setState({ gender: e.target.value })}
            value={this.state.gender}
          >
            <option value="0">{__("หญิง")}</option>
            <option value="1">{__("ชาย")}</option>
          </select>
        </div>
        <div className="uk-text-meta uk-margin-small-top">
          {__(
            "กรณีที่ท่านลงทะเบียนเพื่อใช้งานในโรงพยาบาล หรือ องค์กร กรุณาใส่รหัสเพื่อเข้าใช้งาน(สามารถสอบถามได้ที่พยาบาลหรือฝ่ายทรัพยากรบุคคลของท่าน)"
          )}
        </div>
        <div className="uk-margin-small-top">
          <div>{__("รหัสบริษัท")}</div>
          <input
            type="text"
            className="uk-input"
            disabled={this.state.companyname ? true : false}
            value={this.state.companyid}
            onChange={(e) => this.setState({ companyid: e.target.value })}
          />
        </div>
        {this.props.qs.display !== "location" && (
          <div>
            <div>{__("รหัสแคมเปญ")}</div>
            <input
              type="email"
              className="uk-input"
              disabled={this.state.campaignname ? true : false}
              value={this.state.campaignid}
              onChange={(e) => this.setState({ campaignid: e.target.value })}
            />
          </div>
        )}

        <hr />
        <div>
          <div>
            {__("เลขบัตรประชาชน หรือ เลขบัตรประกันสังคม หรือหมายเลขพาสปอร์ต")}
            <span className="uk-text-danger">*</span>
          </div>
          <input
            type="text"
            className="uk-input"
            value={this.state.nid}
            onChange={(e) => this.setState({ nid: e.target.value })}
          />
        </div>
        <div>
          <div>
            {__("วันเดือนปีเกิด")} ({__("พ.ศ.")})
            <span className="uk-text-danger">*</span>
          </div>

          <DatePicker2
            callback={(d) => {
              this.setState({"birthday": d});
            }}
          />
        </div>
        <div className="uk-margin-small-top">
          <div>{__("รูปภาพ")}</div>
          <div className="uk-input uk-text-right uk-button-group uk-flex-middle">
            <div className="uk-text-truncate uk-width-expand">
              {this.state.image}
            </div>
            <label>
              {__("เรียกดู")}
              <UploadFirebase
                maxWidth={false}
                cb={(image) => {
                  this.setState({ image });
                }}
              />
            </label>
          </div>
        </div>
        <div>
          <div>{__("lineID (ถ้ามี)")}</div>
          <input
            type="text"
            className="uk-input"
            onChange={(e) => this.setState({ lineid: e.target.value })}
          />
        </div>

        <div className="uk-margin-top">
          {__(
            "เราขอข้อมูลวันเดือนปีเกิดและเพศ เพื่อที่จะวิเคราะห์ความเสี่ยงสุขภาพจากอายุและเพศของคุณได้ เช่น ความเสี่ยงโรคไต หรือ โรคเบาหวาน เราขอข้อมูลเลขบัตรประชาชน หรือ เลขบัตรประกันสังคม หรือ หมายเลขพาสปอร์ต เพื่อกรณีเชื่อมโยงผลการตรวจสุขภาพในรูปแบบองค์กรโดยจะเก็บข้อมูลเป็นความลับ ไม่เปิดเผยยังหน่วยงานอื่นใด ที่ไม่ได้เกี่ยวข้องกับการรักษาพยาบาลของท่าน"
          )}
        </div>

        <div className="uk-margin-top">
          <label uk-grid="" className="uk-grid-small">
            <div className="uk-width-auto">
              <input
                type="checkbox"
                className="uk-checkbox"
                id="d-accept-delicate"
                checked={this.state.acceptDelicate === true ? "checked" : ""}
                onChange={() => {
                  this.setState({
                    acceptDelicate: $("#d-accept-delicate").prop("checked"),
                  });
                }}
              />
            </div>
            <div className="uk-width-expand">
              {__("คุณได้อ่านและยอมรับ")}{" "}
              <a
                href="https://dietz.asia/terms.html"
                target="_blank"
                className="uk-link-reset"
                rel="noopener"
              >
                <u>{__("ท่านรับทราบและยอมรับ")}</u>
              </a>{" "}
              {__("และ")}{" "}
              <a
                href="https://dietz.asia/sensitive.html"
                target="_blank"
                className="uk-link-reset"
                rel="noopener"
              >
                <u>
                  {__("การใช้ข้อมูลส่วนบุคคลบุคคลที่มีความอ่อนไหวเป็นพิเศษ")}
                </u>
              </a>
            </div>
          </label>
        </div>

        {this.state.errorTextStep3 && (
          <div className="uk-margin-small-top uk-text-center uk-text-danger uk-padding-small uk-background-muted">
            {this.state.errorTextStep3}
          </div>
        )}
        <div className="uk-margin-small-top">
          <button
            className="uk-button uk-button-primary uk-width-1-1"
            type="button"
            onClick={this.registerConfirm}
          >
            {__("เสร็จสิ้น")}
          </button>
        </div>
      </div>
    );
  };

  lineCircleRender = () => {
    return (
      <div style={{ textAlign: "center", paddingBottom: "10px" }}>
        <div
          style={{
            borderTop: "1px solid #DBDBDB",
            width: "90px",
            display: "inline-block",
            position: "relative",
          }}
        >
          <div
            className={`d-form-login-vector-cirlce${
              this.state.step >= 1 ? " d-form-login-vector-cirlce-active" : ""
            }`}
            style={{ left: 0 }}
          ></div>
          {this.props.qs.method !== "lite" && (
            <div
              className={`d-form-login-vector-cirlce${
                this.state.step >= 2 ||
                (this.props.qs.method === "lite" && this.state.step == 2)
                  ? " d-form-login-vector-cirlce-active"
                  : ""
              }`}
              style={{ left: "50%", marginLeft: "-8px" }}
            ></div>
          )}
          <div
            className={`d-form-login-vector-cirlce${
              this.state.step >= 3 ||
              (this.props.qs.method === "lite" && this.state.step == 2) ? " d-form-login-vector-cirlce-active" : ""
            }`}
            style={{ right: 0 }}
          ></div>
        </div>
      </div>
    );
  };

  campaignListRender = () => {
    if (this.state.campaignList.length === 0) {
      return (
        <div className="uk-padding-small uk-text-center">
          {__("ไม่พบแคมเปญ")}
        </div>
      );
    } else {
      return (
        <div className="uk-margin-top">
          <div className="uk-text-center uk-margin-bottom">
            {__("เลือกแคมเปญ")}
          </div>
          <div>
            {this.state.campaignList.map((value, key) => {
              return (
                <a
                  href={`/register?coid=${this.props.qs.coid}&caid=${value.id}`}
                  key={`campaignList${key}`}
                  className="uk-grid-small uk-flex-middle uk-link-reset"
                  uk-grid=""
                >
                  <div className="uk-width-1-6">
                    <div
                      className="uk-width-small uk-background-contain"
                      style={{
                        paddingTop: "100%",
                        backgroundImage: `url(${ImageUri(value.image)})`,
                      }}
                    ></div>
                  </div>
                  <div className="uk-width-expand">
                    <div className="uk-text-bold uk-margin-small-bottom">
                      <u>{value.name}</u>
                    </div>
                  </div>
                  <div className="uk-width-auto uk-text-right">
                    <span uk-icon="icon: chevron-right;"></span>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      );
    }
  };

  render() {
    if (this.state.can_register !== true) {
      return (
        <div className="d-form-login-body uk-padding-small">
          <div className="uk-card uk-card-default uk-padding d-form-login-container uk-width-1-1 uk-width-1-2@s uk-width-1-4@m">
            <div className="uk-text-center">
              {__("กรุณาติดต่อสถานพยาบาลเพื่อขอลิ้งค์ลงทะเบียนที่ถูกต้อง")}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="d-form-login-body uk-padding-small">
        <div className="uk-card uk-card-default uk-padding uk-padding-remove-top d-form-login-container uk-width-1-1 uk-width-1-2@s uk-width-1-4@m">
          <LanguageLoginSelect />
          {this.state.companyimage && (
            <div className="uk-text-center uk-padding-small">
              <img
                style={{
                  height: 220,
                }}
                src={ImageUri(this.state.companyimage)}
              />
            </div>
          )}
          <div className="uk-text-center uk-padding-small">
            <img src="/logo_dietz.png" />
          </div>
          <h1 className="d-form-login-title uk-margin-small-bottom">
            {__("สมัครใช้บริการสำหรับคนไข้")}
          </h1>

          {this.props.qs.display === "list" && this.props.qs.coid ? (
            this.campaignListRender()
          ) : (
            <div>
              {this.lineCircleRender()}

              {this.state.step === 1
                ? this.step1()
                : this.state.step === 2
                ? this.step2()
                : this.step3()}
            </div>
          )}
        </div>
      </div>
    );
  }
}
