import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Api from '../../Components/Api.js';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import numeral from 'numeral';
import ReactPaginate from 'react-paginate';
import moment from 'moment'; 
import QrReader from 'react-qr-scanner'

UIkit.use(Icons);

export default class PackageOrderList extends Component {
  constructor(props){
    super(props) 

    this.handleScan = this.handleScan.bind(this)
  }

  state = {
    list: [],
    loadingApi: false,
    pageNow: 1,
    pageAmount: 1,
    perPage: 10,
    delay: 100,
    result: 'โปรด Scan QR Code', 
    is_scanner_focus: false,
    qr_value: ''
  }

  QrChecking = false

  qrInput = null

  listGet = async  () => {
    this.setState({loadingApi: true});

    var _response = await Api.consult("M2PackageOrderList", {
      page: this.state.pageNow
    }); 

    this.setState({
      list: _response.list || [],
      loadingApi: false,
      pageAmount: _response.pageAmount || 1
    });
  }

  packageStatusUpdate = async (text) => { 
    if(text.includes('จ') === true) {
      UIkit.notification({
        message: 'โปรดเปลี่ยนการพิมพ์เป็นภาษาอังกฤษ', 
        status: 'danger' 
      });

      this.qrInput.value = '';
      return;
    }

    if(this.QrChecking !== false) return;


    this.QrChecking = true;

    var _r = await Api.consult("M2PackageUse", {
      text: text
    });  
    
    UIkit.notification({
      message: _r.responseText, 
      status: _r.result === true ? 'success' : 'danger' 
    });

    this.setState({
      is_scanner_focus: false
    }, ()=> {
      if(_r.result === true) {
        this.listGet();
      }
    })

    this.QrChecking = false;

    return _r;

  }

  
  handlePageClick = (page) => {
    this.setState({pageNow: page.selected + 1}, ()=> this.listGet())
  }

  paginateRender = () => {
    return <div className="uk-text-right">
      <ReactPaginate
        className={'uk-padding-remove'}
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={this.state.pageAmount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={this.handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
    </div>
  }

  componentDidMount() {
    this.listGet();
  } 
  handleScan(data){
    this.setState({
      result: data,
    })
  }
  handleError(err){
    console.error(err)
  }

  popupRender = () => {
    return <div className="d-popup-background">
    <div className="d-popup-body d-popup-body-small">
      <QrReader
        delay={this.state.delay}
        style={{
          height: 240,
          width: 320,
          border: '1px solid #000'
        }}
        onError={this.handleError}
        onScan={(data)=>{
          if(data != null) {
            this.packageStatusUpdate(data.text);

          }
        }}
        />
      <p className='uk-text-center'>{this.state.result}</p>
      {
        this.state.is_scanner_focus === true ?
        <input
            type="text"
            ref={ref=>(this.qrInput=ref)}
            onChange={(e)=>{  
              if(e.target.value.length === 28) {
                this.packageStatusUpdate(e.target.value); 
              } else if(e.target.value.length > 28) {
                this.qrInput.value = '';

              }
            }}
            onBlur={()=>{
              this.setState({is_scanner_focus: false})
            }}
            style={{
              // visibility: 'hidden',
              position: 'absolute',
              height: 1,
              width: 1,
              zIndex: -1
            }}
            autoFocus
          />
          :
          null
      }
    </div>
    </div>
  }

  render() {
    return <div className="uk-padding">
      {
        this.state.is_scanner_focus && this.popupRender()
      }
      <div className="uk-card uk-card-default uk-padding-small"> 
        <div className="d-card-header">
          <div uk-grid="">
            <div className='uk-width-1-2@m uk-width-1-1'>รายการแพ็คเกจของคนไข้</div>
            <div className='uk-width-1-2@m uk-width-1-1 uk-text-right'>
              <button className='uk-button uk-button-primary' onClick={()=>{
                this.setState({
                  is_scanner_focus: true
                })
              }}>ใช้แพ็คเกจ</button>
            </div>
          </div>
        </div>
        <div className="uk-overflow-auto">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
            <thead>
              <tr>
                <th>ที่</th>
                <th>ID</th>
                <th>ชื่อ-สกุล</th>
                <th>ชื่อแพ็คเกจ</th>
                <th>ราคา</th>
                <th>การใช้งาน</th> 
              </tr>
            </thead>
            <tbody>
              {
                this.state.loadingApi === true ?
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center"><span uk-spinner=""></span></div>
                  </td>
                </tr>
                :
                this.state.list.length === 0 ? 
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center">ไม่พบข้อมูล</div>
                  </td>
                </tr>
                :

                this.state.list.map((data, key)=>{
 

                  var _price = numeral(data.price).format('0,0.00');

                  if(data.price_type === 'multi') {
                    try {
                      var _price_array = JSON.parse(decodeURIComponent(data.price_array));
   
                      var _price_low = Infinity;
                      var _price_high = 0;

                      for(var a of _price_array) {
                        if(parseFloat(a.price) < _price_low) {
                          _price_low = a.price;
                        }

                        if(parseFloat(a.price) > _price_high) {
                          _price_high = a.price;
                        }
                      }

                      if(_price_low === Infinity) {
                        _price_low = 0;
                      }
                      
                      _price = `${numeral(_price_low).format('0,0.00')} - ${numeral(_price_high).format('0,0.00')}`;
                    } catch(e) {
                      console.error(e);
                    }
                  }

                  return <tr key={`row${data.id}`}>
                    <td>{(this.state.perPage * (this.state.pageNow-1 ) + key + 1)}</td>
                    <td>{data.id}</td>
                    <td>{data.nameprefix} {data.name} {data.lastname}</td>
                    <td>
                      {data.title}
                    </td>
                    <td>
                    ฿ {_price}
                    </td>
                    <td>{data.active === "1" ? <><span uk-icon="icon:check"></span> <span>ใช้งานแล้ว</span> {data.date_active != '0000-00-00 00:00:00' ?  <span>({moment(data.date_active).format('วันที่ DD/MM/YYYY HH:mm น.')})</span> : ''}</> : 'รอการใช้งาน'}
                    </td> 
                  </tr>
                })
              }
            </tbody>
          </table>
          <div>
            {this.paginateRender()}
          </div>
          </div>
      </div>
    </div>
  }
};
