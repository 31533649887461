import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import UploadFirebase from '../../Components/UploadFirebase';
import ReactPaginate from 'react-paginate';
import ImageUri from '../../Components/ImageUri';
import moment from 'moment';
import numeral from 'numeral';
import MemberRegisterLocation from '../../Components/Member/MemberRegisterLocation';

class AdminCampaignAddForm extends Component {
  field = [
    { key: 'datestart', label: 'วันที่เริ่มต้น ค.ศ. (ปปปป-ดด-วว)' },
    { key: 'datestop', label: 'วันที่สิ้นสุด ค.ศ. (ปปปป-ดด-วว)' },
  ]

  state = {
    company: [],
    image: '',
    loadingApi: false
  }

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
  }

  campaignStateInit = {
    name: '',
    image: '',
    icon: '',
    datestart: moment().format('YYYY-MM-DD'),
    datestop:  moment().format('YYYY-MM-DD'),
    companyid: '',
    price: '',
    pay: '0',
    caption: '',
    day: '7',
    promocode: "",
    lat: "",
    lng: "",
    address_name: "",
    address: "",
    address_note: "",
    postcode: "",
    district_code: "",
    loadingInit: true,
    appointment_online: "1"
  }

  init = async () => {
    this.setState((state) => {
      var _setState = {};

      if (this.props.campaign) {
        for(var i in this.campaignStateInit) {
          _setState[i] = this.props.campaign[i] || '';
        }
      }
      
      return _setState;
    });

    var { list } = await Api.admin('CompanyList', {allpage : "1"}); 

    this.setState({ company: list, loadingInit: false, loadingApi: false  });

  }

  CampaignAdd = (e) => {
    e.preventDefault();

    if(this.state.loadingApi === true) return false;

    this.setState({loadingApi: true}, async ()=>{
      var _data = {};

      for(var i in this.campaignStateInit) {
        _data[i] = this.state[i] || '';
      }

      if (this.props.campaign) {
        _data.id = this.props.campaign.id;
      } else {
        _data.id = '';
      }
  
      var { result, responseText } = await Api.admin(_data.id ? 'CampaignUpdate' : 'CampaignAdd', _data);


      UIkit.notification({ message: responseText, status: result ? 'success' : 'danger' })
      
      if(result === true) {

        this.props.callback();

        this.props.hideForm();
      }



      this.setState({loadingApi: false});
    })
    
  }


  updateValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return <div className="d-popup-background">
      <div id="modal-form-campaign" className="d-popup-body">
        <div className='uk-text-right'>
          <button uk-icon="close" type="button" onClick={this.props.hideForm} ></button>
        </div>
        <h2 className="uk-modal-title">จัดการแคมเปญ</h2>
        <form onSubmit={this.CampaignAdd} className="uk-width-1-1">
          <div className="uk-margin-small-bottom">
            <label className="uk-button uk-button-default">
              เลือกรูปภาพ
              <UploadFirebase cb={(image) => {
                this.setState({ image })
              }} />
            </label>
            {this.state.image !== '' && <div className="uk-margin-small"><img src={ImageUri(this.state.image)} uk-img="" width="200" /></div>}
          </div>
          <div className="uk-margin-small-bottom">
            <label className="uk-button uk-button-default">
              เลือกไอคอน
              <UploadFirebase name="icon" cb={(icon) => {
                this.setState({ icon })
              }} />
            </label>
            {this.state.icon !== '' && <div className="uk-margin-small"><img src={ImageUri(this.state.icon)} uk-img="" width="200" /></div>}
          </div>
          <div className="uk-margin-small-bottom">
              <label>ชื่อแคมเปญ</label>
              <input type="text" className="uk-input" name={"name"} onChange={this.updateValue} value={this.state.name} />
          </div>
          <div className="uk-margin-small-bottom">
              <label>รายละเอียดแคมเปญ</label>
              <textarea className="uk-textarea" name={"caption"} onChange={this.updateValue} value={this.state.caption} />
          </div>
          <div className="uk-margin-small-bottom">
              <label>แคมเปญสำหรับหาหมอออนไลน์</label>
              <div><label><input type="radio" value="0" name="appointment_online" checked={this.state.appointment_online === '0' ? true : false} onChange={this.updateValue} /> หาหมอที่โรงพยาบาล</label></div>
              <div><label><input type="radio" value="1" name="appointment_online" checked={this.state.appointment_online === '1' ? true : false} onChange={this.updateValue} /> หาหมอออนไลน์</label></div>
          </div>
          <div className="uk-margin-small-bottom">
              <label>ระยะแคมเปญกรณีรายบุคคล</label>
              <select className="uk-select" name="day" onChange={this.updateValue} value={this.state.day}>{
                ['7', '14', '30', '45', '60', '90', '120', '150', '180', '210', '240', '270', '300', '365'].map((data, index)=>{
                  return <option value={data} key={`selectday${index}`}>{data} วัน</option>
                })}
                
              </select>
          </div>
          {
            this.field.map(data => {
              return <div className="uk-margin-small-bottom">
                <label>{data.label}</label>
                <input type="text" className="uk-input" name={data.key} onChange={this.updateValue} value={this.state[data.key]} />
              </div>
            })
          }
          <div className="uk-margin-small-bottom">
              <label>ค่าใช้จ่าย</label>
              <div><label><input type="radio" value="0" name="pay" checked={this.state.pay === '0' ? true : false} onChange={this.updateValue} /> ไม่มีค่าใช้จ่าย</label></div>
              <div><label><input type="radio" value="1" name="pay" checked={this.state.pay === '1' ? true : false} onChange={this.updateValue} /> มีค่าใช้จ่าย</label> ระบุ <input type="number" className="uk-input uk-display-inline uk-width-small" name="price" onChange={this.updateValue} value={this.state.price} /> บาท</div>
              <div>ระบุรหัสโปรโมชัน ตัวเลข 4 ตัว ใช้สำหรับแจ้งให้คนไข้ทราบเพื่อที่คนไข้ไม่ต้องชำระเงิน</div>
              <input type="text" placeholder='รหัสโปรโมชัน' maxLength={4}  className='uk-input' name="promocode" value={this.state.promocode} onChange={this.updateValue} />
          </div>
          <div className="uk-margin-small-top">
            <label>บริษัท</label>
            <select className="uk-select" id="select-company" value={this.state.companyid} onChange={e=>this.setState({companyid: e.target.value})}>
              {this.state.company.map((data, key) => (<option key={`company${key}`} value={data.id} data-name={data.name}>{data.name}</option>))}
            </select>
          </div>
          {this.state.loadingInit === false && this.state.loadingApi === false && <MemberRegisterLocation 
            {...this.props} 
            callback={(data)=>{
              this.setState({
                address_name: data.address_name,
                address: data.address,
                address_note: data.address_note,
                postcode: data.postcode,
                district_code: data.district_code,
                lat: data.lat,
                lng: data.lng,
              });
            }} 
            formType={'admin'} 
            address_name={this.state.address_name} 
            address={this.state.address} 
            address_note={this.state.address_note} 
            postcode={this.state.postcode} 
            district_code={this.state.district_code} 
            lat={this.state.lat} 
            lng={this.state.lng} 
          />}
          <button className="uk-input uk-button-primary uk-margin-small-top" type="submit" disabled={this.state.loadingApi}>{this.props.campaign ? 'บันทึก' : 'เพิ่ม'}</button>
        </form>
      
      </div>
    </div>
  }
}

export default class AdminCampaign extends Component {

  state = {
    campaignList: [],
    formShow: false,
    campaign: false,
    pageCount: 0,
    pageNow: 1,
    keyword: ''
  }

  perPage = 100

  componentDidMount() {
    this.init();
  }

  componentWillReceiveProps() {
    this.init();
  }

  consult = {}

  init = async () => {
    this.campaignListGet();
  }

  campaignListGet = async (e) => {
    if(e != null) e.preventDefault();

    var _response = await Api.admin('CampaignList', {
      perPage : this.perPage,
      pageNow : this.state.pageNow,
      keyword: this.state.keyword
    });

    if(Array.isArray(_response.consult) === true) {
      for(var consult of _response.consult) {
        this.consult[consult.id] = `${consult.nameprefix} ${consult.name} ${consult.lastname}`;
      }
    }

    if(Array.isArray(_response.list) !== true) _response.list = [];

    for(var list of _response.list) {
      list.consult_data = [];

      for(var id of list.consult) {
        if(this.consult[id] != null) {
          list.consult_data.push(this.consult[id]);
        }
      }
    }

    this.setState({ campaignList: _response.list,  pageCount: +_response.amount/this.perPage });
  }

  handlePageClick = (page) => {
    this.setState({pageNow: page.selected+1}, ()=>this.dataListGet())
  }

  paginateRender = () => {
    return <div className="uk-text-right">
            <ReactPaginate
              previousLabel={'previous'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </div>
  }

  render() {
    return <div>
      <div className="d-admin-header-row uk-text-right">
        <form className='uk-display-inline-block uk-margin-right' onSubmit={this.campaignListGet}>
          <input type="text" placeholder='ค้นหาแคมเปญหรือบริษัท' className="uk-input uk-width-small" onChange={(e)=>{
            this.setState({
              keyword: e.target.value
            });
          }} />
          <button className='uk-button uk-button-secondary' uk-icon="search"></button>
        </form>
        <button className="uk-button uk-button-small uk-button-secondary" onClick={() => this.setState({ campaign: false }, () => this.setState({ formShow: true }))} >เพิ่มแคมเปญ</button>
      </div>
      <div>
      {this.state.formShow === true && <div><AdminCampaignAddForm  {...this.props} callback={() => {
        this.campaignListGet();

        
      }} hideForm={()=> {

        this.setState({formShow: false});

      }} campaign={this.state.campaign} /></div> }
      </div>
      {this.paginateRender()}
      <div className='uk-overflow-auto uk-width-1-1'>
      <table className="uk-table uk-table-small uk-table-divider">
        <thead>
          <tr>
            <th>ลำดับ</th>
            <th>รหัสแคมเปญ</th>
            <th>บริษัท</th>
            <th>ชื่อแคมเปญ</th>
            <th>ที่ปรึกษา</th>
            <th>ระยะเวลาแคมเปญ (รายบุคคล)</th>
            <th>ค่าใช้จ่าย</th>
            <th>รหัสโปรโมชัน</th>
            <th>วันที่เริ่มต้น</th>
            <th>วันที่สิ้นสุด</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            this.state.campaignList.map((data, key) => { 
              return <tr key={`Campaign${data.id}`}>
                <td>{key + 1}</td>
                <td>{data.id}</td>
                <td>{data.companyname}</td>
                <td>{data.name}</td>
                <td>{data.consult_data.map((consult)=>{
                  return <span className='uk-label uk-margin-small-right uk-margin-small-bottom'>{consult}</span>
                })}</td>
                <td>{data.day} วัน</td>
                <td>{numeral(data.price).format('0,0.00')}</td>
                <td>{data.promocode}</td>
                <td>{data.datestart}</td>
                <td>{data.datestop}</td>
                <td>
                  <a className="uk-button uk-button-small" href={"/admin/campaignDetail?id="+data.id+"&companyid="+data.companyid+"&companyname="+encodeURIComponent(data.name)+"&campaignname="+encodeURIComponent(data.companyname)}>ปรับปรุงสมาชิก</a>
                  <a className="uk-button uk-button-small" onClick={() => this.setState({ campaign: data }, ()=>{
                    this.setState({formShow: true})
                  })} >
                    แก้ไข
                  </a>
                </td>
              </tr>
            })
          }
        </tbody>
      </table>

      </div>
      {this.paginateRender()}
    </div>
  }
};
